<template>
  <div class="display-container">
    <slot />
  </div>
</template>
<style lang="css" scoped>
.display-container {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* for screens smaller than 1400px (which is at this size that the margin becomes smaller than 55px), constraint to 90vw */
@media screen and (max-width: 1400px) {
  .display-container {
    max-width: 90vw;
  }
}
</style>
